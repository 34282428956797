import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Table } from 'react-bootstrap'
import { FiDownloadCloud } from 'react-icons/fi'
import Swal from 'sweetalert2'
import Lottie from 'react-lottie'
import * as animationData from '../../assets/lottie/201-simple-loader.json'
import docmgmtServices from '../../services/docmgmt'
import config from '../../helpers/config'

const PdfFiles = (props) => {
  const { titleHeader, listFiles, ...rest } = props
  const [listFilesState, setListFiles] = useState([])

  const closeModel = () => {
    props.onHide()
  }

  const changeStatusFiles = (file, status) => {
    const newFiles = listFilesState.map((fileList) => {
      if (fileList.id === file.id) {
        fileList.status = status
      }

      return fileList
    })

    setListFiles(newFiles)
  }

  const forceDownload = async (file) => {
    try {
      changeStatusFiles(file, true)
      const fileGet = await docmgmtServices.downloadFilePdfVersion(file)

      const blob = new Blob([new Uint8Array(fileGet.data).buffer], {
        type: 'application/pdf'
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file.source)
      document.body.appendChild(link)
      link.click()
      changeStatusFiles(file, false)
    } catch (error) {
      Swal.fire('Atention!', 'Error download file.', 'error')
      changeStatusFiles(file, false)
    }
  }

  const updateListFilesStatus = () => {
    const listFilesMap = listFiles.map((file) => {
      file['status'] = false
      return file
    })
    setListFiles(listFilesMap)
  }

  useEffect(() => {
    updateListFilesStatus() // eslint-disable-next-line
  }, [listFiles])

  return (
    <>
      <Modal
        {...rest}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {titleHeader}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>Code</th>
                    <th
                      align='center'
                      style={{ width: '1%', textAlign: 'center' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listFilesState.map((file) => (
                    <tr key={file.id}>
                      <td>{file.name}</td>
                      <td>{file.code}</td>
                      <td align='center' style={{ textAlign: 'center' }}>
                        {file.status ? (
                          <Lottie
                            options={config.configLottie(animationData)}
                            height={25}
                            width={65}
                          />
                        ) : (
                          <FiDownloadCloud
                            size={24}
                            onClick={() => forceDownload(file)}
                            style={{ cursor: 'pointer' }}
                            title='Download?'
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' onClick={closeModel}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PdfFiles
